@font-face {
  font-family: 'Graphik LCG';
  src: url('https://toniaux.com/fonts/GraphikLCG-Regular.woff2') format("woff2"), url('https://toniaux.com/fonts/GraphikLCG-Regular.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  // font-display: swap;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('https://toniaux.com/fonts/GraphikLCG-Medium.woff2') format("woff2"), url('https://toniaux.com/fonts/GraphikLCG-Medium.woff') format("woff");
  font-weight: 500;
  font-style: normal;
  // font-display: swap;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('https://toniaux.com/fonts/GraphikLCG-Semibold.woff2') format("woff2"), url('https://toniaux.com/fonts/GraphikLCG-Semibold.woff') format("woff");
  font-weight: 600;
  font-style: normal;
  // font-display: swap;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('https://toniaux.com/fonts/GraphikLCG-Bold.woff2') format("woff2"), url('https://toniaux.com/fonts/GraphikLCG-Bold.woff') format("woff");
  font-weight: 700;
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('https://toniaux.com/fonts/GraphikLCG-Black.woff2') format("woff2"), url('https://toniaux.com/fonts/GraphikLCG-Black.woff') format("woff");
  font-weight: 900;
  font-style: normal;
  // font-display: swap;
}

body {
  font-size: 16px;
  font-family: "Graphik LCG", "Arial", sans-serif;
}